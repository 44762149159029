import React from "react"
import { Container, Content, SubContainer } from "./styled"
import { Wrapper } from "../../../styled"
import ReactMarkdown from "react-markdown"
import TranslateUp from "../../../utils/translateUp"
//import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const TextAndImage = props => {
  return (
    <Wrapper isLarge={props.position === "ImageDown"} id={props.id}>
      <Container categories={props.categories} position={props.position}>
        <div>
          {props.title && <h1>{props.title}</h1>}
          {props.subTitle && <h2>{props.subTitle}</h2>}
        </div>

        <SubContainer position={props.position}>
          <Content position={props.position}>
            <ReactMarkdown
              source={props.text}
              renderers={{
                link: props => (
                  <a
                    href={props.href}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    {props.children}
                  </a>
                ),
              }}
              escapeHtml={false}
            />
          </Content>

            <GatsbyImage
                image={getImage(props.image)}
                alt={props.image.description || "Agorastore illustration"}
                loading="eager"
            />
        </SubContainer>
      </Container>
    </Wrapper>
  )
}

export default TranslateUp(TextAndImage)
